import React from 'react';
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Capsule from './pages/products/Capsule';
import PreOrder from './pages/PreOrder';
import 'preline/preline';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/products/capsule',
    element: <Capsule />,
  },
  {
    path: '/capsule/pre-order',
    element: <PreOrder />,
  },
]);

export default function App() {
  return (
    <div>
      <RouterProvider router={router} />
      <Toaster />
    </div>
  );
}
