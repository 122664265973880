import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function Description({ children, show }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          transition={{
            duration: 0.3,
            type: 'ease',
            ease: 'easeOut',
            // delay: 0.6,
          }}
          exit={{
            opacity: 0,
            height: 0,
            // transition: {
            //   delay: 0,
            // },
          }}
        >
          <p className=" text-terra-blue text-[24px] leading-[27px]">
            {children}
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Description;
