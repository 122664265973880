import React from 'react';
import Header from '../../shared/Header';
import { Link } from 'react-router-dom';
import terraImgNew from '../../assets/img/terra-img-new.png';
import terraImg from '../../assets/img/ter-img.png';
import terraFront from '../../assets/img/terragen-front.png';
import terraSide from '../../assets/img/terra-side-2.png';

const imgs = [terraImg, terraFront, terraSide];

function Capsule() {
  return (
    <div>
      <Header />
      <div className="relative">
        <div className="h-[100vh] overflow-hidden grid grid-cols-2">
          <div className="h-full bg-[#8394AF] p-8 pl-16 pb-16 flex flex-col justify-center items-start">
            <div className="max-w-[340px]">
              <h1 className="uppercase text-terra-deep-blue font-bold text-[67px] mb-6">
                CAPSULE
              </h1>
              <p className="text-[16.58px] leading-[16.5px] mb-4 text-terra-blue font-bold">
                TerraGen: Sustainable Power Generation with Flywheel Energy
                Storage
              </p>
              <p className="text-[12px] leading-[16.5px] mb-12">
                TerraGen's innovative design allows it to efficiently capture
                and store energy from renewable sources, such as solar or wind
                power, making it a reliable and sustainable option for powering
                homes, businesses, and communities. By utilizing flywheel energy
                storage technology, TerraGen is able to provide a continuous and
                stable source of electricity, reducing reliance on fossil fuels
                and lowering carbon emissions. With its focus on environmental
                and economic sustainability, TerraGen is paving the way for a
                cleaner and greener future.
              </p>
              <Link
                className="bg-[#07296A] bg-opacity-[0.88] text-white h-11 flex items-center justify-center"
                to="/capsule/pre-order"
              >
                Pre-Order Yours
              </Link>
            </div>
          </div>
          <div className="h-full bg-[#91A1BB] p-8 pr-16 pb-16 flex flex-col justify-center items-end gap-4">
            {imgs.map((p, i) => {
              return (
                <div className="h-[204px] w-[202px] bg-[#8394AF] p-6" key={i}>
                  <img src={p} className="h-full w-full object-contain" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="h-[465px] max-w-[612px] absolute top-0 left-0 right-0 bottom-0 m-auto">
          <img src={terraImgNew} className="h-full w-full object-contain" />
        </div>
      </div>
    </div>
  );
}

export default Capsule;
