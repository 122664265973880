import { useForm } from 'formbold-react';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Header from '../shared/Header';

import frontImage from '../assets/img/terragen-front.png';

const FormInput = ({
  name,
  value = '',
  placeholder,
  type,
  required = false,
}) => {
  return (
    <input
      className="w-full h-[65px] px-4 border border-[#446CA8] rounded-[10px] bg-[#D1DCF1] placeholder:text-[17px] text-[#163672] focus:ring-[#446CA8] focus:border-[#446CA8]"
      name={name}
      type={type}
      placeholder={placeholder}
      required={required}
    />
  );
};

function PreOrder() {
  const [state, handleSubmit] = useForm('oPkxb');

  const navigate = useNavigate();

  useEffect(() => {
    if (state.succeeded) {
      toast.success("We've got your details! We will be in touch shortly.");
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <div
      className="h-[100vh] bg-no-repeat"
      style={{
        background: "url('/images/terra-img-new.png')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '728px',
        backgroundPosition: 'center',
      }}
    >
      <Header />
      <form onSubmit={handleSubmit}>
        <div className="h-[100vh] backdrop-blur-lg bg-[#BBCCEB] bg-opacity-[0.79] p-16 pt-36 flex items-start">
          <div className="flex-1">
            <h1 className="font-medium text-[24px] leading-[20px] text-[#163672] mb-12">
              Pre-order Details
            </h1>
            <div className="pr-14 border-r border-[#163672] mr-14">
              <div className="mb-6">
                <FormInput
                  name="company_name"
                  placeholder="Company’s Name  (Optional)"
                  type="text"
                />
              </div>
              <div className="mb-6">
                <FormInput
                  name="full_name"
                  placeholder="Full Name / Contact Person"
                  type="text"
                  required
                />
              </div>
              <div className="mb-6">
                <textarea
                  rows={6}
                  placeholder="Address (Area and Street)"
                  className="w-full p-4 border border-[#446CA8] rounded-[10px] bg-[#D1DCF1] placeholder:text-[17px] text-[#163672] focus:ring-[#446CA8] focus:border-[#446CA8]"
                ></textarea>
              </div>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <FormInput
                  name="email"
                  placeholder="Email"
                  type="email"
                  required
                />
                <FormInput
                  name="phone_number"
                  placeholder="Phone Number"
                  type="text"
                  required
                />
              </div>

              <div className="grid grid-cols-2 gap-6">
                <FormInput
                  name="city"
                  placeholder="City/District/Town"
                  type="text"
                  required
                />
                <FormInput
                  name="state"
                  placeholder="State"
                  type="text"
                  required
                />
              </div>
              <div>
                <button className="py-5 px-3 w-full bg-white rounded-[10px] drop-shadow-lg text-[20px] text-[#163672] font-medium">
                  Pre-Order
                </button>
              </div>
            </div>
          </div>
          <div className="w-[400px] flex flex-col justify-between items-start h-full">
            <div className="flex items-center justify-center text-[23px] font-bold w-full">
              <h2 className="text-[#081F4A] opacity-[0.48]">Cart</h2>
              <div className="border-t-4 border-dashed w-full border-[#081F4A] border-opacity-[0.48]"></div>
              <h2 className="text-[#163672]">Delivery</h2>
            </div>
            <div className="w-full">
              <div className="flex justify-between items-end mb-28">
                <h2 className="text-[#163672] font-bold text-[23px]">
                  Order Summary
                </h2>
                <p className="text-[29px] text-[#1C3B78] font-medium">0.00</p>
              </div>

              <div className="flex items-center justify-start w-full mb-6">
                <h2 className="text-[#163672] font-bold text-[23px] mr-2 w-[200px]">
                  Delivery Summary
                </h2>
                <div className="border-t-2 border-dashed flex-1 border-[#081F4A] border-opacity-[0.48]"></div>
              </div>

              <div className="flex justify-between items-start">
                <img
                  src={frontImage}
                  alt="Terragen"
                  className="max-h-[164px] object-contain"
                />
              </div>

              <div className="border-t-2 border-dashed flex-1 border-[#081F4A] border-opacity-[0.48] my-8"></div>

              <div className="flex justify-between items-center  mb-6">
                <h2 className="text-[#163672] font-bold text-[21px]">
                  Total Payable
                </h2>
                <p className="text-[29px] text-[#1C3B78] font-medium">0.00</p>
              </div>

              <button className="py-5 px-3 w-full bg-[#163672] rounded-[10px] drop-shadow-lg text-[20px] text-white font-medium mb-12">
                Place Order
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PreOrder;
